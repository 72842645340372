import { useEffect, useMemo, useState } from "react"
import { Col, Form, OverlayTrigger, Row, Tooltip, Button } from 'react-bootstrap';
import paypal from 'assets/img/icons/icon-paypal-full.png';
import creditCard from 'assets/img/icons/icon-payment-methods.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
// import countries from 'data/countries';
import "../../../../assets/scss/custom.scss";

import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { apiConfig } from 'config/apiConfig';


const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Roboto, Source Code Pro, monospace, SFUIDisplay",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        },

      }
    }),
    []
  );
  return options;
};

const BillingDetails = () => {

  const stripePromise = loadStripe(
    apiConfig.REACT_APP_STRIPEPUBLISHABLEKEY
  );

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  )
}
export default BillingDetails;

const CheckoutForm = () => {
  const [checkoutError, setCheckoutError] = useState();
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const [isProcessing, setProcessingTo] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [client, setClient] = useState({});
  const options = useOptions();

  const stripe = useStripe();
  const elements = useElements();
  const [searchParams, setSearchParams] = useSearchParams();

  const [amountToPay, setAmountToPay] = useState(0);

  useEffect(() => {
    paymentDetail();
  }, [])

  const paymentDetail = () => {
    const searchParams1 = window.location.search.split("?").pop();
    const clientName = searchParams.get("clientName");
    const projectName = searchParams.get("projectName");
    const email = searchParams.get("email");

    var decrypted = window.CryptoJS.AES.decrypt(searchParams1, apiConfig.REACT_APP_CRYPTOPASSWORD); //process.env.REACT_APP_CRYPTOPASSWORD);
    const decryptedDetail = decrypted.toString(window.CryptoJS.enc.Utf8);
    if (decryptedDetail) {
      const detail = JSON.parse(decryptedDetail);
      setClient(detail);

      setValue("clientName", detail.clientName);
      setValue("projectName", detail.projectName);
      setValue("email", detail.email);
      setAmountToPay(detail.cost);
      // setValue("status", detail.status);
    } else {

      setValue("clientName", clientName);
      setValue("projectName", projectName);
      setValue("email", email);
    }

  }



  const handleCardDetailsChange = event => {
    event.error ? setCheckoutError(event.error.message) : setCheckoutError();
  };


  const submitForm = async (form) => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + apiConfig.REACT_APP_STRIPESECRETKEY);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();

    urlencoded.append("amount", amountToPay * 100);
    urlencoded.append("currency", "usd");
    // urlencoded.append("description", "Test payment");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    setProcessingTo(true);

    fetch("https://api.stripe.com/v1/payment_intents", requestOptions)
      .then(async (response) => {
        const data = await response.json();
        if (data.client_secret) {

          // your billing details object looks like this
          const billingDetails = {
            name: form.name,
            email: client ? client.email : '',
            address: {
              city: "New York",
              line1: "896 Bell Street",
              state: "New York",
              postal_code: "	10022"
            }
          }

          const cardElement = elements.getElement(CardNumberElement)

          try {
            const paymentMethodReq = await stripe.createPaymentMethod({
              type: "card",
              card: cardElement,
              billing_details: billingDetails
            });

            if (paymentMethodReq.error) {
              setCheckoutError(paymentMethodReq.error.message);
              setProcessingTo(false);
              return;
            }

            const confirmedCardPayment = await stripe.confirmCardPayment(data.client_secret, {
              payment_method: paymentMethodReq.paymentMethod.id
            });

            if (confirmedCardPayment.error) {
              setCheckoutError(confirmedCardPayment.error);
              setProcessingTo(false);
              return;
            }

            // display your success messages or redirect user to a success page
            console.log("Payment successful")
            savePaymentDetails(confirmedCardPayment);
            setProcessingTo(false)
          } catch (err) {
            setCheckoutError(err.message);
            setProcessingTo(true);
          }

        } else {
          console.log("Error: Server could not initiate the payment process.")
        }
      })
      .then(result => {
      })

  }

  const savePaymentDetails = (confirmedCardPayment) => {

  }

  const onSubmit = data => {
    console.log(data);
  };

  return (
    <Form onSubmit={handleSubmit(submitForm)}>
      {/* <Form.Check type="radio" className="mb-0 form-check" id="paypal">
        <Form.Check.Input
          type="radio"
          id="paypal"
          name="billing"
          value="paypal"
          {...register('billingMethod', { required: true })}
        />
        <Form.Check.Label className="mb-0 d-block">
          <img src={paypal} height="20" alt="paypal" />
        </Form.Check.Label>
      </Form.Check>
      <p className="fs--1 mb-4">
        Pay with PayPal, Apple Pay, PayPal Credit and much more
      </p> */}

      <Form.Check type="radio" className="mb-0 form-check" id="credit-card">
        <Form.Check.Input
          type="radio"
          name="billing"
          defaultChecked
          id="credit-card"
          value="card"
          {...register('billingMethod', { required: true })}
        />
        <Form.Check.Label className="d-flex align-items-center mb-0">
          <span className="fs-1 text-nowrap">Credit Card</span>
          <img
            src={creditCard}
            height="20"
            className="d-none d-sm-inline-block ms-2 mt-lg-0"
            alt="paypal"
          />
        </Form.Check.Label>
      </Form.Check>
      <p className="fs--1 mb-4">
        Safe money transfer using your bank accounts. Visa, maestro, discover,
        american express.
      </p>
      <Row className="gx-3">
        <Col xs={6} sm={6}>
          <Form.Group>
            <Form.Label
              className="ls text-uppercase text-600 fw-semi-bold mb-0 fs--1"
              htmlFor="cardNumber"
            >
              Card Number
            </Form.Label>
            {/* <Form.Control
            type="text"
            placeholder="XXXX XXXX XXXX XXXX"
            {...register('cardNumber', { required: true })}
          /> */}
            <CardNumberElement options={options} onChange={handleCardDetailsChange} />
          </Form.Group>
        </Col>
        <Col xs={6} sm={6}>
          <Row className="gx-3">
            <Col xs={6} sm={6}>
              <Form.Group>
                <Form.Label
                  className="ls text-uppercase text-600 fw-semi-bold mb-0 fs--1"
                  htmlFor="expDate"
                >
                  Exp Date
                </Form.Label>
                {/* <Form.Control
              id="expDate"
              placeholder="15/2024"
              type="text"
              {...register('expDate', { required: true })}
            /> */}
                <CardExpiryElement options={options} onChange={handleCardDetailsChange} />
              </Form.Group>
            </Col>
            <Col xs={6} sm={6}>
              <Form.Group>
                <Form.Label
                  className="ls text-uppercase text-600 fw-semi-bold mb-0 fs--1"
                  htmlFor="cvv"
                >
                  CVV{''}
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip style={{ position: 'fixed' }}>
                        Card verification value
                      </Tooltip>
                    }
                  >
                    <Link to="#!">
                      <FontAwesomeIcon
                        icon="question-circle"
                        className="ms-1 text-600"
                      />
                    </Link>
                  </OverlayTrigger>
                </Form.Label>
                {/* <Form.Control
              id="cvv"
              placeholder="123"
              maxLength="3"
              pattern="[0-9]{3}"
              type="text"
              {...register('cvv', { required: true })}
            /> */}
                <CardCvcElement options={options} onChange={handleCardDetailsChange} class="form-control" />
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="gx-3">
        <Col xs={6} sm={6}>
          <Form.Group >
            <Form.Label
              className="ls text-uppercase text-600 fw-semi-bold mb-0 fs--1"
              htmlFor="cardName"
            >
              Name of Card
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="John Doe"
              {...register('cardName', { required: true })}
            />
          </Form.Group>
        </Col>
        <Col xs={6} sm={6}>
          <Row className="gx-3">
            <Col xs={6} sm={6}>
              <Form.Group>
                <Form.Label
                  className="ls text-uppercase text-600 fw-semi-bold mb-0 fs--1"
                  htmlFor="inputCountry"
                >
                  Country
                </Form.Label>
                <Form.Control
                  list="country-list"
                  {...register('country', { required: true })}
                />
                {/* <datalist id="country-list">
                  {countries.map(country => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </datalist> */}
              </Form.Group>
            </Col>
            <Col xs={6} sm={6}>
              <Form.Group>
                <Form.Label
                  className="ls text-uppercase text-600 fw-semi-bold mb-0 fs--1"
                  htmlFor="zipCode"
                >
                  Zip Code
                </Form.Label>
                <Form.Control
                  placeholder="1234"
                  type="text"
                  {...register('zipCode', { required: true })}
                />
              </Form.Group>
            </Col>

          </Row>
        </Col>
      </Row>


      <Form.Check.Label className="d-flex align-items-center my-4">
        <span className="fs-1 text-nowrap">Client Details</span>
      </Form.Check.Label>


      <Row className="gx-3">
        <Col xs={6} sm={6}>
          <Form.Group >
            <Form.Label
              className="ls text-uppercase text-600 fw-semi-bold mb-0 fs--1"
              htmlFor="clientName"
            >
              Client Name
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="John Doe"
              {...register('clientName', { required: true })}
            />
          </Form.Group>
        </Col>
        <Col xs={6} sm={6}>
          <Form.Group >
            <Form.Label
              className="ls text-uppercase text-600 fw-semi-bold mb-0 fs--1"
              htmlFor="projectName"
            >
              Project Name
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="TMS"
              {...register('projectName', { required: true })}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="gx-3">
        <Col xs={6} sm={6}>
          <Form.Group >
            <Form.Label
              className="ls text-uppercase text-600 fw-semi-bold mb-0 fs--1"
              htmlFor="email"
            >
              Email
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="johndoe@email.com"
              {...register('email', { required: true })}
            />
          </Form.Group>
        </Col>
        {/* <Col xs={6} sm={6}>
          <Form.Group >
            <Form.Label
              className="ls text-uppercase text-600 fw-semi-bold mb-0 fs--1"
              htmlFor="status"
            >
              Status
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Pending"
              {...register('status', { required: true })}
            />
          </Form.Group>
        </Col> */}
      </Row>

      <Button type="submit" variant="primary" className="d-block w-100 mt-3">
        <FontAwesomeIcon icon="lock" className="me-2" />
        Make Payment
      </Button>
    </Form>
  );
};

// export default BillingDetails;
