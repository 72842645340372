import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import Billing from 'components/app/e-commerce/billing/Billing';

const FalconRoutes = () => {
  return (
    <Routes>

      <Route element={<MainLayout />}>
        {/*Dashboard*/}
        <Route path="/" element={<Billing />} />
        <Route path="e-commerce/billing" element={<Billing />} />

        {/* //--- MainLayout end  */}
      </Route>
      {/* <Navigate to="/errors/404" /> */}
      <Route path="*" element={<Navigate to="/errors/404" replace />} />
    </Routes>
  );
};

export default FalconRoutes;
